import React, { useState, useContext } from "react";
import {
  Alert, Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
//images
import qrcode from "../../assets/images/iabaduu/qrcode.png";
// toast
import {CaricoToastNotify, CaricoToastErrore, CaricoToastDuplicato } from "helpers/toast_helper";

//AWS
import { generateClient } from 'aws-amplify/api';
import * as mutations from "../../graphql/mutations";
import { loadMagazzino, findIdByUDI } from "../../helpers/magazzino_helper";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
const client = generateClient();

import {UdiSplit, UdiCompose} from "./udisplit";


const Load = props => {
  const { setMagazzino} = useContext(GlobalDataContext);
  // Floating labels forms
  const initialValues = {
    UDI: "",
    DataCarico: "",
    GTIN: "",
    Scadenza: "",
    Lotto: "",
    Seriale: ""
  }
  const validationSchema = Yup.object().shape({
    UDI: Yup.string(),
    GTIN: Yup.string().required("Obbligatorio").matches("^(?:\\d{14})$", "Errore nel formato"),
    DataCarico: Yup.string().required("Obbligatorio"),
    Scadenza: Yup.string().required("Obbligatorio"),
    Lotto: Yup.string().required("Obbligatorio"),
    Seriale: Yup.string().required("Obbligatorio"),
  })

  const {magazzino} = useContext(GlobalDataContext);
  // LOAD data to  Magazzino1
  async function createMagazzino1(data) {
    data.utenteID = JSON.parse(localStorage.getItem("authUser")).sub;
    var udi = document.getElementById("UDI").value;
    const cerca = findIdByUDI(magazzino, udi);
    console.log(cerca);
     
    try {
      if (cerca !== null){
          CaricoToastDuplicato(`Prodotto già presente nel magazzino`); // totoast
        }else{   
      const newMagazzino1 = await client.graphql({
        query: mutations.createMagazzino1,
        variables: { input: data }
      });
      //mando in modale
      const newData = newMagazzino1.data.createMagazzino1;
      CaricoToastNotify(newData.DataCarico, newData.GTIN, newData.Lotto,
                        newData.Scadenza, newData.Seriale); //send to toast
      const mydata = await loadMagazzino();
      setMagazzino(mydata);
      //in caso di errore
    }} catch (error) {
      console.error("Error creating new magazzino:", error);
      CaricoToastErrore(`Errore nel caricamento: ${error.message}`); // totoast
    }
  
}
//reset udi full OK
  const [value, setValue] = useState("");
  const onInput = (e) => setValue(e.target.value);

  function handleOnChange(values, setFieldValue) {
    return (event) => {
      console.log(event.target.value, event.target.name);
      try {
        if (event.target.name == "UDI") {
          const {sUDI, sGTIN, aSCAD, sLOTTO, sSERIALE, DATACARICO} = UdiSplit(event.target.value);
          setFieldValue("UDI", sUDI.toString());
          setFieldValue("GTIN", sGTIN.toString());
          setFieldValue("Scadenza", aSCAD);
          setFieldValue("Lotto", sLOTTO.toString());
          setFieldValue("Seriale", sSERIALE.toString());
          setFieldValue("DataCarico", DATACARICO);
        } else {
            values[event.target.name] = event.target.value;
            const udi = UdiCompose(values.GTIN, values.Scadenza, values.Lotto, values.Seriale).toString()
            values.UDI = udi
            setFieldValue("UDI", udi);
        }
      } catch(error) {
        console.log(error);
      }
    }
  }

  // Set meta title
  document.title = "Load | UDI";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t("UDI")} breadcrumbItem={props.t("Carico prodotti")} />
          <Formik
            initialValues={ initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
              await createMagazzino1(values)
              setSubmitting(false);
              resetForm();
            }}
          >
          {({ errors, touched, isSubmitting, values, setFieldValue }) => (
          <Form name="form" onChange={handleOnChange(values, setFieldValue)}>
          {/* START content */}
          <Row>
            <Col xs={12}>
              <Card>
                  <CardBody>
                    <div className="d-flex align-items-center hstack gap-3">
                        <div className="flex-shrink-0 me-3">
                            <img className="rounded me-2" width="200" src={qrcode} alt="qrcode"></img>
                        </div>
                        <div className="flex-grow-1">
                            <h5>Scansiona qui il QRcode UDI del prodotto</h5>
                            <p>
                            <Field autoFocus className="form-control" type="text" name="UDI" id="UDI" placeholder="UDI" />
                            </p>
                        </div>
                    </div>
                  </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h5">Inserimento manuale codici UDI</CardTitle>

                    {/* Your form fields */}
                  <Row>
                  <Col md={2}>
                    <div className="form-floating mb-3">
                      <Field type="date" name="DataCarico" className="form-control" id="DataCarico" placeholder="Data di Carico"/>
                      <label htmlFor="DataCarico">Data di carico</label>
                      {errors.DataCarico && touched.DataCarico ? (
                        <span className="text-danger">{errors.DataCarico}</span>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="form-floating mb-3">
                      <Field type="string" name="GTIN" className="form-control" id="GTIN" placeholder="Identificativo del prodotto"/>
                      <label htmlFor="GTIN">ID prodotto (01)</label>
                      {errors.GTIN && touched.GTIN ? (
                        <span className="text-danger">{errors.GTIN}</span>
                      ) : null}
                    </div>
                    </Col>
                      <Col md={2}>
                        <div className="form-floating mb-3">
                          <Field type="date" name="Scadenza" className="form-control" id="Scadenza" placeholder="Data di scadenza"/>
                          <label htmlFor="Scadenza">Scadenza (17)</label>
                          {errors.Scadenza && touched.Scadenza ? (
                        <span className="text-danger">{errors.Scadenza}</span>
                      ) : null}
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-floating mb-3">
                          <Field type="text" name="Lotto" className="form-control" id="Lotto" placeholder="Lotto"/>
                          <label htmlFor="Lotto">Lotto (10)</label>
                          {errors.Lotto && touched.Lotto ? (
                        <span className="text-danger">{errors.Lotto}</span>
                      ) : null}
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-floating mb-3">
                          <Field type="text" name="Seriale" className="form-control" id="Seriale" placeholder="Seriale"/>
                          <label htmlFor="Seriale">Seriale (21)</label>
                          {errors.Seriale && touched.Seriale ? (
                        <span className="text-danger">{errors.Seriale}</span>
                      ) : null}
                        </div>
                      </Col>
                      <Col md={1}>
                        <div className="flex-shrink-0 mb-3">
                        <button type="submit" disabled={isSubmitting}
                          className="btn btn-success w-md bottom"
                          data-toggle="modal"
                          data-target="#myModal"
                          >Carica</button>
                        </div>
                      </Col>
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          </Form>
          )}
          </Formik>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(Load);
