import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

//Import Components
import Accordian from "./accordian"

const FAQs = () => {
  const [activeTab, setactiveTab] = useState("1")

  return (
    <React.Fragment>
      <section className="section" id="faqs">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">FAQs</div>
                <h4>Domande frequenti</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="vertical-nav">
                <Row>
                  <Col lg="2" sm="4">
                    <Nav pills className="flex-column">
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          setactiveTab("1")
                        }}
                      >
                        <i className="bx bx-help-circle nav-icon d-block mb-2"/>
                        <p className="font-weight-bold mb-0">
                          Generali
                        </p>
                      </NavLink>

                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          setactiveTab("2")
                        }}
                      >
                        <i className="bx bx-barcode nav-icon d-block mb-2"/>
                        <p className="font-weight-bold mb-0">Funzionamento</p>
                      </NavLink>

                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          setactiveTab("3")
                        }}
                      >
                        <i className="mdi mdi-scale-balance d-block nav-icon mb-2"/>
                        <p className="font-weight-bold mb-0">Normativa</p>
                      </NavLink>
                    </Nav>
                  </Col>
                  <Col lg="10" sm="8">
                    <Card>
                      <CardBody>
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId="1" id="buy">
                            <h4 className="card-title mb-4">
                              Domande Generali
                            </h4>

                            {/* accoridan */}
                            <Accordian
                              question1="Cos'è il codice UDI ?"
                              answer1="Il sistema di Identificazione Unica del Dispositivo (UDI) è uno strumento normativo inteso a identificare univocamente i dispositivi medici durante la loro distribuzione e utilizzo. Si tratta di un'iniziativa globale guidata da varie agenzie normative, tra cui la Food and Drug Administration (FDA) degli Stati Uniti, con l'obiettivo di migliorare la sicurezza dei pazienti, aumentare l'efficacia dei richiami dei dispositivi e facilitare una migliore segnalazione degli eventi avversi legati ai dispositivi.
                              Un UDI è un codice alfanumerico unico che viene assegnato a un dispositivo medico." 
                              question2="Come è Composto il codice UDI ?"
                              answer2="Ogni dispositivo medico viene identificato tramite un codice UDI, composto da una parte identificativa del dispositivo (UDI-DI) e una identificativa della produzione (UDI-PI), che può rappresentare il numero di serie, il numero di lotto, la data di fabbricazione e la data di scadenza.

                              Questo codice è leggibile sia dalle macchine (ad esempio, attraverso codici a barre o QR code) che dall'uomo (numeri e lettere)."
                              question3="Dove viene applicato il codice UDI ?"
                              answer3="L'UDI deve essere posizionato sull'etichetta del dispositivo e sul confezionamento del dispositivo e, per certi dispositivi, deve essere anche marcato direttamente sul dispositivo stesso. L'UDI deve essere presentato in due forme:
                              Una forma leggibile dall'uomo, che può essere facilmente letta e compresa dalle persone, e
                              Una forma leggibile dalla macchina, come un codice a barre o un'etichetta RFID, che può essere scansionata e interpretata dai sistemi elettronici.
                              "
                              question4="Cosa fare se il dispositivo acquistato non presenta il codice UDI?"
                              answer4="Se il dispositivo è senza Codice UDI, non si è tenuti alla registrazione elettronica del dispositivo."
                            />
                          </TabPane>

                          <TabPane tabId="2">
                            <h4 className="card-title mb-4">Funzionamento</h4>

                            <Accordian
                              question1="Devo acquistare un lettore di codici a barre ?"
                              answer1="Il sistema permette l'inserimento manuale dei codici ma per un utilizzo reale è assolutamente consigliato un lettore laser."
                              question2="E' richiesto un lettore in particolare ?"
                              answer2="No, anche un lettore laser di fascia bassa funziona benissimo, l'unico requisito fondamentale è che legga i codici 2D, possono essere utilizzati anche i lettori software su smartphone e tablet. "
                              question3="Dove lo possoa acquistare ?"
                              answer3="In qualsiasi negozio di prodotti elettronici o su Amazon, un prodotto molto economico e funzionale è <a href='https://amzn.to/4ezQvBw'>https://amzn.to/4ezQvBw</a>"
                              question4="Devo registrare tutti i prodotti ?"
                              answer4="No, se sulla confezione del Dispositivo Medico NON c’è il codice UDI allora NON c’è obbligo di registrazione e conservazione elettronica dei dati"
                            />
                          </TabPane>

                          <TabPane tabId="3">
                            <h4 className="card-title mb-4">Normativa</h4>

                            <Accordian
                              question1="Quando è entrato in vigore l'obbligo di tenuta del registro magazzino UDI ?"
                              answer1="Il Decreto dell Ministero della Salute 11 Maggio 2023 'Decreto 11 maggio 2023: identificazione, tracciabilità e nomenclatura dei dispositivi medici', dispone l'obbligo di registrazione e conservazione dell'identificativo unico del dispositivo (UDI) da parte delle istituzioni sanitarie e degli operatori sanitari"
                              question2="Da quando è in vigore l'obbligo?"
                              answer2="L'obbligo di registrazione e conservazione dell’UDI decorre dal 15 gennaio 2024. "
                              question3="Ci sono sanzioni per la mancata tenuta del registro 'UDI ?"
                              answer3="Per i sanitari che non registrino i codici UDI, il dlgs 137/22 prevede la sanzione amministrativa da euro 4.000 a 24.500."
                              question4="Devo registrare tutti i prodotti ?"
                              answer4="No, se sulla confezione del Dispositivo Medico NON c’è il codice UDI allora NON c’è obbligo di registrazione e conservazione elettronica dei dati"
                            />
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default FAQs
