/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createInvoiceCounter = /* GraphQL */ `
  mutation CreateInvoiceCounter(
    $input: CreateInvoiceCounterInput!
    $condition: ModelInvoiceCounterConditionInput
  ) {
    createInvoiceCounter(input: $input, condition: $condition) {
      id
      invoiceId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInvoiceCounter = /* GraphQL */ `
  mutation UpdateInvoiceCounter(
    $input: UpdateInvoiceCounterInput!
    $condition: ModelInvoiceCounterConditionInput
  ) {
    updateInvoiceCounter(input: $input, condition: $condition) {
      id
      invoiceId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInvoiceCounter = /* GraphQL */ `
  mutation DeleteInvoiceCounter(
    $input: DeleteInvoiceCounterInput!
    $condition: ModelInvoiceCounterConditionInput
  ) {
    deleteInvoiceCounter(input: $input, condition: $condition) {
      id
      invoiceId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFatture = /* GraphQL */ `
  mutation CreateFatture(
    $input: CreateFattureInput!
    $condition: ModelFattureConditionInput
  ) {
    createFatture(input: $input, condition: $condition) {
      id
      data_fattura
      prodotto
      importo_totale
      imponibile
      iva
      inizio_abbonamento
      fine_abbonamento
      emessa
      utenteID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFatture = /* GraphQL */ `
  mutation UpdateFatture(
    $input: UpdateFattureInput!
    $condition: ModelFattureConditionInput
  ) {
    updateFatture(input: $input, condition: $condition) {
      id
      data_fattura
      prodotto
      importo_totale
      imponibile
      iva
      inizio_abbonamento
      fine_abbonamento
      emessa
      utenteID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFatture = /* GraphQL */ `
  mutation DeleteFatture(
    $input: DeleteFattureInput!
    $condition: ModelFattureConditionInput
  ) {
    deleteFatture(input: $input, condition: $condition) {
      id
      data_fattura
      prodotto
      importo_totale
      imponibile
      iva
      inizio_abbonamento
      fine_abbonamento
      emessa
      utenteID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createImmagini = /* GraphQL */ `
  mutation CreateImmagini(
    $input: CreateImmaginiInput!
    $condition: ModelImmaginiConditionInput
  ) {
    createImmagini(input: $input, condition: $condition) {
      id
      filename
      link
      width
      height
      dataimmagine
      Utente {
        id
        id_cognito
        email
        nome
        cognome
        piva
        indirizzo
        citta
        cap
        cell
        cf
        sdi
        paese
        status
        provincia
        id_stripe
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Paziente {
        id
        Nome
        Cognome
        CF
        DataNascita
        Sesso
        Telefono
        email
        indirizzo
        cap
        citta
        Allergie
        Malattie
        Terapie
        PrecedentiClinici
        TrattamentiPrecedenti
        utenteID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      immaginiUtenteId
      immaginiPazienteId
      __typename
    }
  }
`;
export const updateImmagini = /* GraphQL */ `
  mutation UpdateImmagini(
    $input: UpdateImmaginiInput!
    $condition: ModelImmaginiConditionInput
  ) {
    updateImmagini(input: $input, condition: $condition) {
      id
      filename
      link
      width
      height
      dataimmagine
      Utente {
        id
        id_cognito
        email
        nome
        cognome
        piva
        indirizzo
        citta
        cap
        cell
        cf
        sdi
        paese
        status
        provincia
        id_stripe
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Paziente {
        id
        Nome
        Cognome
        CF
        DataNascita
        Sesso
        Telefono
        email
        indirizzo
        cap
        citta
        Allergie
        Malattie
        Terapie
        PrecedentiClinici
        TrattamentiPrecedenti
        utenteID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      immaginiUtenteId
      immaginiPazienteId
      __typename
    }
  }
`;
export const deleteImmagini = /* GraphQL */ `
  mutation DeleteImmagini(
    $input: DeleteImmaginiInput!
    $condition: ModelImmaginiConditionInput
  ) {
    deleteImmagini(input: $input, condition: $condition) {
      id
      filename
      link
      width
      height
      dataimmagine
      Utente {
        id
        id_cognito
        email
        nome
        cognome
        piva
        indirizzo
        citta
        cap
        cell
        cf
        sdi
        paese
        status
        provincia
        id_stripe
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Paziente {
        id
        Nome
        Cognome
        CF
        DataNascita
        Sesso
        Telefono
        email
        indirizzo
        cap
        citta
        Allergie
        Malattie
        Terapie
        PrecedentiClinici
        TrattamentiPrecedenti
        utenteID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      immaginiUtenteId
      immaginiPazienteId
      __typename
    }
  }
`;
export const createPaziente = /* GraphQL */ `
  mutation CreatePaziente(
    $input: CreatePazienteInput!
    $condition: ModelPazienteConditionInput
  ) {
    createPaziente(input: $input, condition: $condition) {
      id
      Nome
      Cognome
      CF
      DataNascita
      Sesso
      Telefono
      email
      indirizzo
      cap
      citta
      Allergie
      Malattie
      Terapie
      PrecedentiClinici
      TrattamentiPrecedenti
      utenteID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePaziente = /* GraphQL */ `
  mutation UpdatePaziente(
    $input: UpdatePazienteInput!
    $condition: ModelPazienteConditionInput
  ) {
    updatePaziente(input: $input, condition: $condition) {
      id
      Nome
      Cognome
      CF
      DataNascita
      Sesso
      Telefono
      email
      indirizzo
      cap
      citta
      Allergie
      Malattie
      Terapie
      PrecedentiClinici
      TrattamentiPrecedenti
      utenteID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePaziente = /* GraphQL */ `
  mutation DeletePaziente(
    $input: DeletePazienteInput!
    $condition: ModelPazienteConditionInput
  ) {
    deletePaziente(input: $input, condition: $condition) {
      id
      Nome
      Cognome
      CF
      DataNascita
      Sesso
      Telefono
      email
      indirizzo
      cap
      citta
      Allergie
      Malattie
      Terapie
      PrecedentiClinici
      TrattamentiPrecedenti
      utenteID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUtente = /* GraphQL */ `
  mutation CreateUtente(
    $input: CreateUtenteInput!
    $condition: ModelUtenteConditionInput
  ) {
    createUtente(input: $input, condition: $condition) {
      id
      id_cognito
      email
      Magazzino1s {
        nextToken
        startedAt
        __typename
      }
      nome
      cognome
      piva
      indirizzo
      citta
      cap
      cell
      Pazientes {
        nextToken
        startedAt
        __typename
      }
      Fattures {
        nextToken
        startedAt
        __typename
      }
      cf
      sdi
      paese
      status
      provincia
      id_stripe
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUtente = /* GraphQL */ `
  mutation UpdateUtente(
    $input: UpdateUtenteInput!
    $condition: ModelUtenteConditionInput
  ) {
    updateUtente(input: $input, condition: $condition) {
      id
      id_cognito
      email
      Magazzino1s {
        nextToken
        startedAt
        __typename
      }
      nome
      cognome
      piva
      indirizzo
      citta
      cap
      cell
      Pazientes {
        nextToken
        startedAt
        __typename
      }
      Fattures {
        nextToken
        startedAt
        __typename
      }
      cf
      sdi
      paese
      status
      provincia
      id_stripe
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUtente = /* GraphQL */ `
  mutation DeleteUtente(
    $input: DeleteUtenteInput!
    $condition: ModelUtenteConditionInput
  ) {
    deleteUtente(input: $input, condition: $condition) {
      id
      id_cognito
      email
      Magazzino1s {
        nextToken
        startedAt
        __typename
      }
      nome
      cognome
      piva
      indirizzo
      citta
      cap
      cell
      Pazientes {
        nextToken
        startedAt
        __typename
      }
      Fattures {
        nextToken
        startedAt
        __typename
      }
      cf
      sdi
      paese
      status
      provincia
      id_stripe
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createContatto = /* GraphQL */ `
  mutation CreateContatto(
    $input: CreateContattoInput!
    $condition: ModelContattoConditionInput
  ) {
    createContatto(input: $input, condition: $condition) {
      id
      Nome
      Cognome
      email
      Telefono
      Note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateContatto = /* GraphQL */ `
  mutation UpdateContatto(
    $input: UpdateContattoInput!
    $condition: ModelContattoConditionInput
  ) {
    updateContatto(input: $input, condition: $condition) {
      id
      Nome
      Cognome
      email
      Telefono
      Note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteContatto = /* GraphQL */ `
  mutation DeleteContatto(
    $input: DeleteContattoInput!
    $condition: ModelContattoConditionInput
  ) {
    deleteContatto(input: $input, condition: $condition) {
      id
      Nome
      Cognome
      email
      Telefono
      Note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createProdotti1 = /* GraphQL */ `
  mutation CreateProdotti1(
    $input: CreateProdotti1Input!
    $condition: ModelProdotti1ConditionInput
  ) {
    createProdotti1(input: $input, condition: $condition) {
      id
      GTIN
      Nome
      Produttore
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateProdotti1 = /* GraphQL */ `
  mutation UpdateProdotti1(
    $input: UpdateProdotti1Input!
    $condition: ModelProdotti1ConditionInput
  ) {
    updateProdotti1(input: $input, condition: $condition) {
      id
      GTIN
      Nome
      Produttore
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteProdotti1 = /* GraphQL */ `
  mutation DeleteProdotti1(
    $input: DeleteProdotti1Input!
    $condition: ModelProdotti1ConditionInput
  ) {
    deleteProdotti1(input: $input, condition: $condition) {
      id
      GTIN
      Nome
      Produttore
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMagazzino1 = /* GraphQL */ `
  mutation CreateMagazzino1(
    $input: CreateMagazzino1Input!
    $condition: ModelMagazzino1ConditionInput
  ) {
    createMagazzino1(input: $input, condition: $condition) {
      id
      UDI
      GTIN
      Lotto
      Produzione
      Scadenza
      Seriale
      DataCarico
      DataScarico
      utenteID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateMagazzino1 = /* GraphQL */ `
  mutation UpdateMagazzino1(
    $input: UpdateMagazzino1Input!
    $condition: ModelMagazzino1ConditionInput
  ) {
    updateMagazzino1(input: $input, condition: $condition) {
      id
      UDI
      GTIN
      Lotto
      Produzione
      Scadenza
      Seriale
      DataCarico
      DataScarico
      utenteID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteMagazzino1 = /* GraphQL */ `
  mutation DeleteMagazzino1(
    $input: DeleteMagazzino1Input!
    $condition: ModelMagazzino1ConditionInput
  ) {
    deleteMagazzino1(input: $input, condition: $condition) {
      id
      UDI
      GTIN
      Lotto
      Produzione
      Scadenza
      Seriale
      DataCarico
      DataScarico
      utenteID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
