import React, { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// users
import user1 from "../../../assets/images/iabaduu/person.png";

import { GlobalDataContext } from "../../../contexts/GlobalDataContext";

//AWS
import { signOut, fetchUserAttributes } from 'aws-amplify/auth';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { loadMagazzino, loadNomiProdotti} from "helpers/magazzino_helper";

async function handleSignOut() {
  try {
    await signOut();
    localStorage.removeItem("authUser");
    console.log('LOGOUT');
  } catch (error) {
    console.log('error signing out: ', error);
  }
}
const ProfileMenu = props => {
  const { magazzino, setMagazzino, nomiProdotti, setNomiProdotti } = useContext(GlobalDataContext);
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    async function getUserAttributes() {
      let userAttributes = {};
      try {
        userAttributes = await fetchUserAttributes();
        await setUser(userAttributes)
        await localStorage.setItem("authUser", JSON.stringify(userAttributes));
        console.log('SET USER');
        const data = await loadMagazzino();
        setMagazzino(data);
        const data2 = await loadNomiProdotti();
        setNomiProdotti(data2);
        console.log('SET MAGAZZINO', data);
      } catch (error) {
        console.log(error);
      }
      return userAttributes;
    }
    getUserAttributes();
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
           
          <span className="d-none d-xl-inline-block ms-2 me-1">{user.email}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="/abbonamento">
            <i className="bx bx-euro font-size-16 align-middle me-1" />
            {props.t("Abbonamento")}
          </DropdownItem>
          <DropdownItem tag="a" href="/impostazioni">
            <span className="badge bg-success float-end"></span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="/support">
            <span className="badge bg-success float-end"></span>
            <i className="bx bx-support font-size-16 align-middle me-1" />
            {props.t("Supporto")}
          </DropdownItem>

          <div className="dropdown-divider" />
          <Link to="#" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span onClick={handleSignOut}>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withAuthenticator(withTranslation()(ProfileMenu)))
);
