import React from "react"
import { Container, Row, Col, Badge } from "reactstrap"
import { Link } from "react-router-dom"
//import { blogs } from "../../../common/data";
import codiceudi from "../../../assets/images/iabaduu/CodiceUDI.png";

const Blog = () => {

  return (
    <React.Fragment>
      <section className="section bg-white" id="news">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">UDI</div>
                <h4>Composizione del codice</h4>
              </div>
            </Col>
          </Row>

          <Row>
              <Col xl="12" sm="12">
                <div className="d-flex align-items-center">
              <img src={codiceudi} alt="codiceudi" className="img-fluid" width="100%"></img>
              </div>
              </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Blog
