/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getInvoiceCounter = /* GraphQL */ `
  query GetInvoiceCounter($id: ID!) {
    getInvoiceCounter(id: $id) {
      id
      invoiceId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listInvoiceCounters = /* GraphQL */ `
  query ListInvoiceCounters(
    $filter: ModelInvoiceCounterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoiceCounters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        invoiceId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInvoiceCounters = /* GraphQL */ `
  query SyncInvoiceCounters(
    $filter: ModelInvoiceCounterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInvoiceCounters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        invoiceId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFatture = /* GraphQL */ `
  query GetFatture($id: ID!) {
    getFatture(id: $id) {
      id
      data_fattura
      prodotto
      importo_totale
      imponibile
      iva
      inizio_abbonamento
      fine_abbonamento
      emessa
      utenteID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFattures = /* GraphQL */ `
  query ListFattures(
    $filter: ModelFattureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFattures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        data_fattura
        prodotto
        importo_totale
        imponibile
        iva
        inizio_abbonamento
        fine_abbonamento
        emessa
        utenteID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFattures = /* GraphQL */ `
  query SyncFattures(
    $filter: ModelFattureFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFattures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        data_fattura
        prodotto
        importo_totale
        imponibile
        iva
        inizio_abbonamento
        fine_abbonamento
        emessa
        utenteID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const fatturesByUtenteID = /* GraphQL */ `
  query FatturesByUtenteID(
    $utenteID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFattureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fatturesByUtenteID(
      utenteID: $utenteID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data_fattura
        prodotto
        importo_totale
        imponibile
        iva
        inizio_abbonamento
        fine_abbonamento
        emessa
        utenteID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getImmagini = /* GraphQL */ `
  query GetImmagini($id: ID!) {
    getImmagini(id: $id) {
      id
      filename
      link
      width
      height
      dataimmagine
      Utente {
        id
        id_cognito
        email
        nome
        cognome
        piva
        indirizzo
        citta
        cap
        cell
        cf
        sdi
        paese
        status
        provincia
        id_stripe
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Paziente {
        id
        Nome
        Cognome
        CF
        DataNascita
        Sesso
        Telefono
        email
        indirizzo
        cap
        citta
        Allergie
        Malattie
        Terapie
        PrecedentiClinici
        TrattamentiPrecedenti
        utenteID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      immaginiUtenteId
      immaginiPazienteId
      __typename
    }
  }
`;
export const listImmaginis = /* GraphQL */ `
  query ListImmaginis(
    $filter: ModelImmaginiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImmaginis(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        filename
        link
        width
        height
        dataimmagine
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        immaginiUtenteId
        immaginiPazienteId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncImmaginis = /* GraphQL */ `
  query SyncImmaginis(
    $filter: ModelImmaginiFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImmaginis(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        filename
        link
        width
        height
        dataimmagine
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        immaginiUtenteId
        immaginiPazienteId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPaziente = /* GraphQL */ `
  query GetPaziente($id: ID!) {
    getPaziente(id: $id) {
      id
      Nome
      Cognome
      CF
      DataNascita
      Sesso
      Telefono
      email
      indirizzo
      cap
      citta
      Allergie
      Malattie
      Terapie
      PrecedentiClinici
      TrattamentiPrecedenti
      utenteID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPazientes = /* GraphQL */ `
  query ListPazientes(
    $filter: ModelPazienteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPazientes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Nome
        Cognome
        CF
        DataNascita
        Sesso
        Telefono
        email
        indirizzo
        cap
        citta
        Allergie
        Malattie
        Terapie
        PrecedentiClinici
        TrattamentiPrecedenti
        utenteID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPazientes = /* GraphQL */ `
  query SyncPazientes(
    $filter: ModelPazienteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPazientes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Nome
        Cognome
        CF
        DataNascita
        Sesso
        Telefono
        email
        indirizzo
        cap
        citta
        Allergie
        Malattie
        Terapie
        PrecedentiClinici
        TrattamentiPrecedenti
        utenteID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const pazientesByUtenteID = /* GraphQL */ `
  query PazientesByUtenteID(
    $utenteID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPazienteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pazientesByUtenteID(
      utenteID: $utenteID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Nome
        Cognome
        CF
        DataNascita
        Sesso
        Telefono
        email
        indirizzo
        cap
        citta
        Allergie
        Malattie
        Terapie
        PrecedentiClinici
        TrattamentiPrecedenti
        utenteID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUtente = /* GraphQL */ `
  query GetUtente($id: ID!) {
    getUtente(id: $id) {
      id
      id_cognito
      email
      Magazzino1s {
        nextToken
        startedAt
        __typename
      }
      nome
      cognome
      piva
      indirizzo
      citta
      cap
      cell
      Pazientes {
        nextToken
        startedAt
        __typename
      }
      Fattures {
        nextToken
        startedAt
        __typename
      }
      cf
      sdi
      paese
      status
      provincia
      id_stripe
      createdAt
      updatedAt 
      _version
      _deleted
      __typename
    }
  }
`;
export const listUtentes = /* GraphQL */ `
  query ListUtentes(
    $filter: ModelUtenteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUtentes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        id_cognito
        email
        nome
        cognome
        piva
        indirizzo
        citta
        cap
        cell
        cf
        sdi
        paese
        status
        provincia
        id_stripe
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUtentes = /* GraphQL */ `
  query SyncUtentes(
    $filter: ModelUtenteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUtentes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        id_cognito
        email
        nome
        cognome
        piva
        indirizzo
        citta
        cap
        cell
        cf
        sdi
        paese
        status
        provincia
        id_stripe
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getContatto = /* GraphQL */ `
  query GetContatto($id: ID!) {
    getContatto(id: $id) {
      id
      Nome
      Cognome
      email
      Telefono
      Note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listContattos = /* GraphQL */ `
  query ListContattos(
    $filter: ModelContattoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContattos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Nome
        Cognome
        email
        Telefono
        Note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncContattos = /* GraphQL */ `
  query SyncContattos(
    $filter: ModelContattoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContattos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Nome
        Cognome
        email
        Telefono
        Note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProdotti1 = /* GraphQL */ `
  query GetProdotti1($id: ID!) {
    getProdotti1(id: $id) {
      id
      GTIN
      Nome
      Produttore
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listProdotti1s = /* GraphQL */ `
  query ListProdotti1s(
    $filter: ModelProdotti1FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProdotti1s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        GTIN
        Nome
        Produttore
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProdotti1s = /* GraphQL */ `
  query SyncProdotti1s(
    $filter: ModelProdotti1FilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProdotti1s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        GTIN
        Nome
        Produttore
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMagazzino1 = /* GraphQL */ `
  query GetMagazzino1($id: ID!) {
    getMagazzino1(id: $id) {
      id
      UDI
      GTIN
      Lotto
      Produzione
      Scadenza
      Seriale
      DataCarico
      DataScarico
      utenteID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listMagazzino1s = /* GraphQL */ `
  query ListMagazzino1s(
    $filter: ModelMagazzino1FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMagazzino1s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        UDI
        GTIN
        Lotto
        Produzione
        Scadenza
        Seriale
        DataCarico
        DataScarico
        utenteID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMagazzino1s = /* GraphQL */ `
  query SyncMagazzino1s(
    $filter: ModelMagazzino1FilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMagazzino1s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        UDI
        GTIN
        Lotto
        Produzione
        Scadenza
        Seriale
        DataCarico
        DataScarico
        utenteID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const magazzino1sByUtenteID = /* GraphQL */ `
  query Magazzino1sByUtenteID(
    $utenteID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMagazzino1FilterInput
    $limit: Int
    $nextToken: String
  ) {
    magazzino1sByUtenteID(
      utenteID: $utenteID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        UDI
        GTIN
        Lotto
        Produzione
        Scadenza
        Seriale
        DataCarico
        DataScarico
        utenteID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
