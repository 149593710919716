import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Login2 from "../pages/Authentication/Login2";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import Success from "../pages/Authentication/Success";
import Errore from "../pages/Authentication/Errore";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ChangePwd from "../pages/Authentication/ChangePassword";
import Sottoscrizione from "../pages/Authentication/Sottoscrizione";

// Public Pages ----------------
import Welcome from "../pages/Public/welcome";
import PagesFaqs from "../pages/Public/faqs";
import PublicSite from "../pages/Publicsite/index";

// Pages ----------------
import Dashboard from "../pages/Dashboard/index";
import Load from "../pages/Load/load";
import Unload from "../pages/Unload/unload";
import Magazzino from "../pages/Stock/magazzino";
import Archivi from "../pages/Extra/archivi";
import Infoudi from "../pages/Extra/infoudi";
import Test from "../pages/Extra/test";
// User pages
import UserProfile from "../pages/User/user-profile";
import UserSupport from "../pages/User/user-support";
import UserSubscription from "../pages/User/user-subscription";
import UserSettings from "../pages/User/user-settings";


import TrattamentoViso from "../pages/Prestazioni/trattamentoviso";
import Trattamento from "../pages/Prestazioni/trattamento";
/*
import Gallery from "../pages/Prestazioni/gallery";
import PazientiList from "../pages/Pazienti/pazienti-list";
import PazienteScheda from "../pages/Pazienti/paziente-scheda";
import ConsensoInformato from "../pages/Prestazioni/consensoinformato";
*/

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/load", component: <Load /> },
  { path: "/unload", component: <Unload /> },
  { path: "/magazzino", component: <Magazzino /> },
  { path: "/archivi", component: <Archivi /> },
  { path: "/infoudi", component: <Infoudi /> },
  { path: "/test", component: <Test /> },
  { path: "/support", component: <UserSupport /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/abbonamento", component: <UserSubscription /> },
  { path: "/impostazioni", component: <UserSettings /> },
  
  { path: "/trattamento", component: <Trattamento /> },
  { path: "/trattamentoviso", component: <TrattamentoViso /> },
  /*
  { path: "/gallery", component: <Gallery /> },
  { path: "/pazienti", component: <PazientiList /> },
  { path: "/pazientescheda", component: <PazienteScheda /> },
  { path: "/consensoinformato", component: <ConsensoInformato /> },
*/

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/website" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/login2", component: <Login2 /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/change-password", component: <ChangePwd /> },
  { path: "/register", component: <Register /> },
  { path: "/success", component: <Success /> },
  { path: "/errore", component: <Errore /> },
  { path: "*", component: <Welcome /> }, //404
  { path: "/Faqs", component: <PagesFaqs /> },
  { path: "/website", component: <PublicSite /> },
  { path: "/", component: <PublicSite /> },
  { path: "/sottoscrizione", component: <Sottoscrizione /> },
];

export { authProtectedRoutes, publicRoutes };
