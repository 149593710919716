import React, { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { images as initialImages, CustomImage } from "./getimages";
import { generateClient } from 'aws-amplify/api';
import * as mutations from '../../graphql/mutations';

const client = generateClient();

const App = () => {
  const [index, setIndex] = useState(-1);
  const [images, setImages] = useState(initialImages);
  const [hasSelected, setHasSelected] = useState(false);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const handleSelect = (index) => {
    const nextImages = images.map((image, i) => 
      i === index ? { ...image, isSelected: !image.isSelected } : image
    );
    setImages(nextImages);
    setHasSelected(nextImages.some(image => image.isSelected));
  };

  const handleSelectAllClick = () => {
    const selectAll = !hasSelected;
    const nextImages = images.map((image) => ({
      ...image,
      isSelected: selectAll,
    }));
    setImages(nextImages);
    setHasSelected(selectAll);
  };

  const handleDeleteSelected = async () => {
    const selectedImages = images.filter(image => image.isSelected);

    for (const image of selectedImages) {
      const imageDetails = {
        id: image.id
      };

      try {
        console.log(`Attempting to delete image with id: ${image.id}`);

        const deletedImage = await client.graphql({
          query: mutations.deleteImmagini,
          variables: { input: imageDetails }
        });

        if (deletedImage.data) {
          console.log(`Successfully deleted image with id: ${image.id}`, deletedImage);
        } else {
          console.error(`Failed to delete image with id: ${image.id}`, deletedImage.errors);
        }

      } catch (error) {
        console.error(`Error deleting image with id: ${image.id}`, error);
      }
    }

    const nextImages = images.filter(image => !image.isSelected);
    setImages(nextImages);
    setHasSelected(false);
    if (index >= nextImages.length) {
      setIndex(-1);
    }
  };

  return (
    <div>
      <div className="p-t-1 p-b-1">
        <button onClick={handleSelectAllClick}>
          {hasSelected ? "Clear selection" : "Select all"}
        </button>
        <button onClick={handleDeleteSelected} disabled={!hasSelected}>
          Delete selected
        </button>
      </div>
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={true}
        onSelect={handleSelect}
      />
      {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
  );
};

export default App;
