import { isArray } from "lodash";

import * as mutations from "graphql/mutations";

import { generateClient } from 'aws-amplify/api';
import { listMagazzino1s, listProdotti1s } from '../graphql/queries';


async function loadNomiProdotti(){
  let ret = {}
  const client = generateClient();
  const nomiProdotti = ret = await client.graphql({ query: listProdotti1s });
  ret = nomiProdotti.data.listProdotti1s.items
  return ret;
}


async function loadMagazzino() {
  let ret = []
  if(localStorage.getItem("authUser")){
    console.log("Load magazzino");
    const client = generateClient();
    const user = JSON.parse(localStorage.getItem("authUser")).sub;
    const variables = {
        filter: {
          utenteID: {
            eq: user
          }
        },
        limit: 1000
    };
    var index = 0;
    do {
      const allMagazzino = await client.graphql({ query: listMagazzino1s, variables: variables});
      const newData = allMagazzino.data.listMagazzino1s.items;
      ret = ret.concat(newData)
      variables.nextToken = allMagazzino.data.listMagazzino1s.nextToken;
      index++;
    } while(variables.nextToken)
    console.log('n. of pages:' + index);
  } else {
    console.log("No auth user");
  }
  return ret;
}

function findNomeByGTIN(gtin, nomiProdotti) {
  if (!gtin) return 'GTIN not provided'; // Check if GTIN is null or undefined
  const items = nomiProdotti || [];
  const product = items.find(item => item.GTIN == gtin); // Ensure exact match
  return product ? product.Nome : '---';
}

function filterItemsWithNullDataScarico(arr, nomiProdotti) {
  var ret = []
  if(isArray(arr)){
      // Filter the array to include only those items where DataScarico is null
      const filteredMagazzino = arr.filter(item => item.DataScarico === null);
      ret = formatMagazzino(filteredMagazzino, nomiProdotti);
  }
  return ret;
}

function formatMagazzino(magazzino, nomiProdotti) {
  var newMagazzino = [];
  if(isArray(magazzino) && isArray(nomiProdotti)){
      newMagazzino = JSON.parse(JSON.stringify(magazzino));
      newMagazzino.forEach(function (item) {
          /*
          const udi = item.UDI;
          const regexGTIN = /(?<=\)01=)(.{14}?)/gm;
          const match = udi.match(regexGTIN);
          const extractedGTIN = match ? match[0] : null; // Get the first matched GTIN, if any
          */
          const extractedGTIN = item.GTIN;
          const productName = extractedGTIN ? findNomeByGTIN(extractedGTIN, nomiProdotti) : 'GTIN not extracted';
          item.Nome = productName
      });
  }
  return newMagazzino;
}

async function unloadItemFromMagazzino(id){
  //data di oggi
  let ret = {};
  let currentDate = new Date().toJSON().slice(0, 10);
  const toUpdate = {
      id: id,
      //id: '66f65e0b-3eb8-49ee-9586-de8150c819ad',
      //UDI: ')01=07640173234012)10=22392JL0)17=241031)21=22392JL05445',
      DataScarico: currentDate
  };
  const client = generateClient();
  try {
      const updateMagazzino1 = await client.graphql({
          query: mutations.updateMagazzino1,
          variables: { input: toUpdate }
      });
      //mando in modale
      ret = updateMagazzino1.data.updateMagazzino1;
      console.log('Scarico', ret);
  } catch (error) {
      throw error
  }
  return ret;
}

function findIdByUDI(arr, udi) {
  // Find the element in the array where the UDI matches the provided UDI
  const item = arr.find(element => element.UDI == udi);
  // If the item exists, return its id; otherwise, return null
  return item ? item.id : null;
}

function filterItemsInScadenza(arr, nomiProdotti) {
   // Filter the array to check expiring product in 2 months
  const today = new Date();
  // Create a new date instance for manipulation
  const futureDate = new Date(today);
  // Add two months to the date
  futureDate.setMonth(futureDate.getMonth() + 2);
  const dateTwoMonthsFromToday = futureDate.toISOString().split('T')[0]; // Formats the date to YYYY-MM-DD
  var ret = []
  if(Array.isArray(arr)){
      // Filter the array to include only those items where DataScarico is null
      const filteredMagazzino = arr.filter(item => item.Scadenza < dateTwoMonthsFromToday && item.DataScarico === null);
      ret = formatMagazzino(filteredMagazzino, nomiProdotti);
  }
  return ret;
}

export { loadMagazzino, loadNomiProdotti, formatMagazzino, 
         filterItemsWithNullDataScarico, unloadItemFromMagazzino, findIdByUDI, filterItemsInScadenza };
